
import { computed, defineComponent, onMounted, ref } from "vue";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { displayErrors } from "@/core/helpers/errors";

interface SelectItemMutual {
  uuid: string;
  name: string;
}

export default defineComponent({
  name: "toolbar-actions",
  components: {},
  setup() {
    const daterange = ref([]);
    const mutual = ref("");
    const loadingMutuales = ref<boolean>(false);
    const optionsMutuales = ref<SelectItemMutual[]>([]);
    const shortcuts = [
      {
        text: "Última semana",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "Último mes",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "Últimos 3 meses",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        },
      },
    ];

    onMounted(() => {
      setTimeout(() => {
        if (store.getters.showFilterMutual){
          loadMutuales();
        }

        if (store.getters.showDateRange) {
          getInitialDateRangeValue();
        }
      }, 1000);
    });

    const updateDaterangeValue = (value) => {
      store.dispatch(Actions.TA_UPDATE_DATE_RANGE_ACTION, value);
    };

    const getInitialDateRangeValue = () => {
      daterange.value = store.getters.getDateRangeValue;
    };

    const loadMutuales = () => {
      loadingMutuales.value = true;
      const params = {
        page_number: 1,
        page_size: 100,
        sort_order: "asc",
        sort_field: "name",
        search: mutual.value,
      };
      store
        .dispatch(Actions.ADMIN_MUTUAL_LIST, params)
        .then(() => {
          optionsMutuales.value = store.state.AdminMutualesModule.list.items;
          loadingMutuales.value = false;
        })
        .catch(() => {
          displayErrors(store, "AdminMutualesModule");
          loadingMutuales.value = false;
        });
    };

    const changeMutual = () => {
      store.dispatch(Actions.TA_UPDATE_FILTER_MUTUAL_ACTION, mutual.value);
    };

    return {
      store,
      daterange,
      updateDaterangeValue,
      shortcuts,
      mutual,
      loadingMutuales,
      loadMutuales,
      optionsMutuales,
      changeMutual,
    };
  },
});
