import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ms-4"
}
const _hoisted_2 = {
  key: 1,
  class: "ms-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.store.getters.showFilterMutual)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_select, {
            filterable: "",
            clearable: "",
            loading: _ctx.loadingMutuales,
            modelValue: _ctx.mutual,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mutual) = $event)),
            placeholder: "Seleccione la mutual",
            style: {"width":"100%"},
            onChange: _ctx.changeMutual
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsMutuales, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item.uuid,
                  label: item.name,
                  value: item.uuid
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["loading", "modelValue", "onChange"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.store.getters.showDateRange)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.daterange,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.daterange) = $event)),
            type: "daterange",
            "unlink-panels": "",
            "range-separator": " - ",
            "start-placeholder": "Desde",
            "end-placeholder": "Hasta",
            shortcuts: _ctx.shortcuts,
            size: "default",
            format: "DD/MM/YYYY",
            "value-format": "YYYY-MM-DD",
            onChange: _ctx.updateDaterangeValue
          }, null, 8, ["modelValue", "shortcuts", "onChange"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}