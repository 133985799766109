
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }))
        .catch((err) => {
          console.log('errr', err);
        })
    };

    const getUser = () => {
      return store.getters.getUser;
      //return AuthModule.state.user;
    };

    return {
      signOut,
      getUser,
      store,
    };
  },
});
