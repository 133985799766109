const DocMenuConfig = [
  {
    heading: "#MUTUAL#",
    role: "ROLE_ADMIN_MUTUAL",
    pages: [
      {
        heading: "Panel de control",
        route: "/mutual/dashboard",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Solicitudes de crédito",
        route: "/mutual/orders",
        svgIcon: "/media/icons/duotune/files/fil008.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Planes de pago",
        route: "/mutual/payment-plans",
        svgIcon: "/media/icons/duotune/finance/fin010.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Catálogo",
        route: "/mutual/catalog",
        svgIcon: "/media/icons/duotune/files/fil001.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Usuarios",
        route: "/mutual/users",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "#MUTUAL#",
    role: "ROLE_USER_MUTUAL",
    pages: [
      {
        heading: "Panel de control",
        route: "/mutual/dashboard",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Solicitudes de crédito",
        route: "/mutual/orders",
        svgIcon: "/media/icons/duotune/files/fil008.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Administración",
    role: "ROLE_ADMIN",
    pages: [
      {
        heading: "Dashboard",
        route: "/admin/dashboard",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Mutuales",
        route: "/admin/mutuales",
        svgIcon: "/media/icons/duotune/ecommerce/ecm004.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Solicitudes de crédito",
        route: "/admin/orders",
        svgIcon: "/media/icons/duotune/files/fil008.svg",
        fontIcon: "bi-layers",
      },
      {
        sectionTitle: "Catálogo",
        route: "/admin/catalog/products",
        svgIcon: "/media/icons/duotune/files/fil001.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Productos",
            route: "/admin/catalog/products",
          },
          {
            heading: "Categorías",
            route: "/admin/catalog/categories",
          },
          {
            heading: "Marcas",
            route: "/admin/catalog/brands",
          },
        ]
      },
      {
        heading: "Usuarios",
        route: "/admin/users",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default DocMenuConfig;
